//
// user-variables.scss
// Use this to overwrite Bootstrap and Dashkit variables
//

// Example of a variable override to change Dashkit's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

//
// Dashkit =====================================
//

// Fonts
$font-family-sans-serif: -apple-system, "Inter", sans-serif; // SF, then Inter
$font-weight-light: 200; // lighter
$btn-font-weight: 600; // heavier
$headings-font-weight: 700; // heavier
$h1-font-size: 1.875rem;

// Color system

$flatWhite: #edf1f2;
$flatWhiteDark: #bec4c8;
$flatSkyBlue: #3b9ad9;
$flatSkyBlueDark: #2f81b8;
$flatYellow: #ffcc2f;
$flatYellowDark: #fea829;
$flatRed: #e64e42;
$flatRedDark: #bf3a31;
$flatOrange: #e57f31;
$flatOrangeDark: #d25519;
$flatNavyBlue: #354a5d;
$flatNavyBlueDark: #2d3e50;
$flatBlack: #2b2b2b;
$flatBlackDark: #262626;
$flatPurple: #745ec5;
$flatPurpleDark: #5b48a2;
$flatGreen: #39cb75;
$flatGreenDark: #30ad63;
$flatMint: #2abc9d;
$flatMintDark: #23a086;
$flatWatermelon: #ed737d;
$flatWatermelonDark: #d8565c;
$flatPlum: #5d365d;
$flatPlumDark: #4f2c4f;
$flatGray: #95a5a6;
$flatGrayDark: #7f8c8d;

// $blue: $flatSkyBlue;
// $purple: $flatPurple;
// $pink: $flatWatermelon;
// $red: $flatRed;
// $orange: $flatOrange;
// $yellow: $flatYellow;
// $green: $flatGreen;
// $teal: $flatMint;

$light-blue: #9cdcfe;
$body-bg: #f5f7fa; // just a _slightly_ darker version of the default
$card-border-color: #e3ebf6;
$purple: $flatPurple;
$black: #1c2f47;
// $black: $flatNavyBlue;
// $black-dark: $flatNavyBlueDark;

$primary: $purple;
$component-active-bg: #00d97e;

// Paths

$path-to-fonts: "/fonts";
$path-to-img: "./../static/images";

/* set the overriding variables */
$grid-breakpoints: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
  xxxxl: 1920px,
);
$container-max-widths: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
  xxxxl: 1920px,
);

// Navbar

// $white: #ffffff !default;
// $gray-100: #f9fbfd !default;
//$gray-200: #edf2f9 !default;
// $gray-300: #e3ebf6 !default;
$gray-400: #d2ddec;
// $gray-500: #b1c2d9 !default;
// $gray-600: #95aac9 !default;
// $gray-700: #6e84a3 !default;
// $gray-800: #3b506c !default;
// $gray-900: #283e59 !default;

$navbar-vertical-width: calc(max(270px, min(24%, 320px)));
$navbar-dark-color: #95aac9;
// $navbar-dark-bg: $flatPurpleDark;

$tooltip-color: $gray-400;
$tooltip-bg: $black;

// So that we don't have tiny modals for things
$modal-card-body-max-height: auto;
