.loader {
  position: relative;
  min-height: 18px;
  min-width: 18px;
}
@keyframes loader {
  0% { left: -100px }
  100% { left: 110%; }
}
.loading-box {
  width: 12px;
  height: 12px;
  background: #2b2b2b;
  animation: animate .5s linear infinite;
  position: absolute;
  top: 0;
  left: 2px;
  border-radius: 3px;
}
@keyframes animate {
  17% { border-bottom-right-radius: 3px; }
  25% { transform: translateY(9px) rotate(22.5deg); }
  50% {
    transform: translateY(9px) scale(1,.9) rotate(45deg) ;
    border-bottom-right-radius: 9px;
  }
  75% { transform: translateY(4.5px) rotate(67.5deg); }
  100% { transform: translateY(0) rotate(90deg); }
}
.loading-shadow {
  width: 16px;
  height: 3px;
  background: #000;
  opacity: 0.1;
  position: absolute;
  top: 20px;
  left: 0;
  border-radius: 50%;
  animation: shadow .5s linear infinite;
}
@keyframes shadow {
  50% {
    transform: scale(1.2,1);
  }
}
