$flatNavyBlue: #354a5d;
$flatNavyBlueDark: #2d3e50;

.print-preview {
  background-color: white;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  font-size: 13px;

  .avoid-page {
    page-break-inside: avoid;
  }
}

.fe {
  &.h1,
  &.h2,
  &.h3,
  &.h4,
  &.h5,
  &.h6 {
    font-weight: normal !important;
  }
}

// Makes text more difficult to see, but cleaner. Maybe use one day?
// * {
.btn {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.alert .btn-close {
  padding-top: 0;
}

.is-invalid .invalid-feedback {
  display: block;
}

.form-label {
  color: #6e84a3;
  font-size: 0.95em;
  font-weight: 500;
}

/**
Kiosk preview.
*/

@function stripe-box-shadow-1($base) {
  @return transparentize($base, 0.25) 0px 13px 27px -5px,
    transparentize($base, 0.3) 0px 8px 16px -8px;
}

@keyframes gradientAnimation {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.kiosk-preview {
  width: 100%;
  margin: 0 auto;
  padding: 4.6%;
  background-color: #000;
  border-radius: 2.7vw;
  box-shadow: stripe-box-shadow-1(rgba(0, 0, 0, 0.5));
}

.kiosk-screen {
  aspect-ratio: 4.3 / 3;
  border-radius: 2.2%; // Slightly rounded corners for the screen
  overflow: hidden;
  position: relative;

  background: linear-gradient(
    30deg,
    $flatMintDark,
    $flatPurple,
    $flatMintDark,
    $flatWatermelon,
    $flatPurpleDark,
    $flatSkyBlue,
    $flatPurple,
    $flatWatermelonDark,
    $flatMintDark,
    $flatPurple,
    $flatMintDark
  );
  background-size: 1200% 1200%;
  animation: gradientAnimation 25s linear infinite;
}

.kiosk-content {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 6.6%;
  color: white;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.kiosk-logo {
  position: absolute;
  top: 7.81%;
  right: 6.83%;
  max-width: 20%;
  max-height: 13%;
  object-fit: contain;
}

.kiosk-heading {
  font-size: 5.65vw;
  font-weight: 900;
  margin-top: 6%;
  margin-bottom: 2.5%;
  line-height: 1.2;
  letter-spacing: -0.04em;
}

.kiosk-message {
  font-size: 1vw;
  font-weight: 300;
  margin-bottom: 5%;
  max-width: 80%;
  line-height: 1.4;
  letter-spacing: -0.02em;
}

.kiosk-buttons {
  display: flex;
  gap: 6%;
}

.kiosk-button {
  display: flex;
  align-items: center;
  border: none;
  padding: 1.2vw 2vw 1.3vw 2vw;
  border-radius: 3.75vw;
  font-size: 0.8vw;
  font-weight: 600;
  text-decoration: none;
  transition: background-color 0.3s;
  white-space: nowrap;
}

.kiosk-button-primary {
  background-color: $flatMint;
  color: white;
}

.kiosk-button-secondary {
  background-color: $flatNavyBlueDark;
  color: white;
}

.sentence-input {
  line-height: 3.5em;
}

/**
Modals:
The way we do modals, we apply the .fade class but we don't run any javascript to 
fade them in from the top, so they aren't perfectly centered. This fixes that.
*/

.modal.fade .modal-dialog {
  transform: none !important;
}

/**
Kiosk actions
*/

$kioskBackgroundColor: darken($flatNavyBlueDark, 10%);

.bg-black {
  background-color: $kioskBackgroundColor;
}

.kiosk-question {
  color: white;
  font-size: 2em;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: -0.04em;
  margin: 0;
}

.kiosk-index {
  color: white;
  width: 2em;
  min-width: 2em;
  height: 2em;
  min-height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $flatNavyBlue;
  color: desaturate(lighten($flatNavyBlue, 35%), 10%);
  font-size: 1em;
  font-weight: 800;
  border-radius: 100px;
}

.kiosk-answer {
  color: $text-muted;
  font-size: 1.2em;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: -0.02em;
}

.kiosk-question-divider {
  opacity: 0.1;
  background-color: $text-muted;
}

.kiosk-action {
  background-color: $flatNavyBlue;
  border-radius: 100px;

  @include media-breakpoint-down(xs) {
    border-radius: 20px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: lighten($flatNavyBlue, 5%);
  }
}

@keyframes gradient-rotation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.kiosk-action-title {
  font-size: 1.6em;
  font-weight: 800;
  letter-spacing: -0.04em;
}

.kiosk-actions-heading {
  color: lighten($flatNavyBlue, 15%); // Fallback color
  font-size: 6vmax;
  font-weight: 900;
  line-height: 1.1;
  letter-spacing: -0.04em;

  // Gradient text effect
  background: linear-gradient(
    45deg,
    $flatPurple,
    $flatMintDark,
    $flatSkyBlue,
    $flatSkyBlueDark,
    $flatPurpleDark,
    $flatPurple,
    $flatMintDark,
    $flatPurple,
    $flatMintDark
  );
  background-size: 800% 800%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  animation: gradient-rotation 180s ease infinite;
}

.kiosk-actions-footer {
  color: darken($flatNavyBlue, 5%);
  text-align: center;
  line-height: 1.4;
  letter-spacing: 0.05em;
  & a,
  & a:hover {
    color: darken($flatNavyBlue, 5%);
  }
}

.bg-flat-purple {
  background-color: saturate(lighten($flatPurple, 2%), 10%);
}

.bg-flat-mint {
  background-color: saturate(lighten($flatMint, 2%), 10%);
}

.bg-flat-skyblue {
  background-color: saturate(lighten($flatSkyBlue, 2%), 10%);
}

.kiosk-message-prompt {
  border: 2px dashed mix($kioskBackgroundColor, $info, 65%);
  background-color: mix($kioskBackgroundColor, $info, 96%);
  padding: 2em;
  border-radius: 1em;
  color: mix($kioskBackgroundColor, $info, 5%);
  margin-top: 2em;
}

body .kiosk-message-container .form-control:focus {
  background-color: lighten($kioskBackgroundColor, 7%) !important;
  border-color: mix($purple, $kioskBackgroundColor, 70%) !important;
}

.kiosk-message-container {
  label {
    color: lighten(saturate($kioskBackgroundColor, 10%), 55%);
  }

  .form-control {
    font-size: 1.1em;
    font-weight: 600;
    padding: 1em 1.3em;
    color: white !important;
    background-color: lighten($kioskBackgroundColor, 3%);
    border: 2px solid lighten($kioskBackgroundColor, 10%);

    &.is-invalid {
      border-color: mix($kioskBackgroundColor, $danger, 50%);
    }

    &::placeholder {
      color: lighten(saturate($kioskBackgroundColor, 5%), 45%);
    }
  }
}

.kiosk-description {
  color: lighten($kioskBackgroundColor, 55%);
}

/**
Dropdowns
*/

select.form-control {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E") !important;
  background-position: right 0.75rem center !important;
  background-repeat: no-repeat !important;
  background-size: 16px 12px !important;
}

thead {
  th:first-child {
    border-top-left-radius: var(--bs-card-border-radius);
  }
  th:last-child {
    border-top-right-radius: var(--bs-card-border-radius);
  }
}

/**
Theme editing
*/

$themeBgColor: $gray-100;
$lightSuccess: lighten($success, 55%);

i.md-12 {
  font-size: 12px;
  max-width: 12px;
}

#form-editor-container-container .inner-side-shadow.dark {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  border-top-right-radius: 20px;
  box-shadow: inset -90px 0 55px -35px mix($flatPurple, $black-dark, 0%);
}

.inner-side-shadow.white {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  border-top-right-radius: 20px;
  box-shadow: inset -90px 0 55px 5px white;
}

.element-selectors {
  .element-selector {
    background-color: $gray-100;
    border-top: 1px solid $gray-100;
    border-bottom: 1px solid $gray-100;

    .row.mb-3,
    .form-group {
      margin-bottom: 0 !important;

      label {
        color: $dark;
        font-size: 13px;
        margin-bottom: 0.5em;
      }
    }

    &:not(.noclick) {
      cursor: pointer;

      &:hover {
        h5 {
          color: $gray-700;
        }
      }
    }

    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &.selected {
      background-color: white;
      border-color: $gray-200;

      h5 {
        color: $success;
      }
    }
  }
}

.text-bigger {
  font-size: 2em;
}

// Remove side padding for vision
#theme-card #theme-editor-container {
  .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

#theme-editor-container {
  #rbgcp-wrapper > div:nth-child(2) {
    & > div:first-child {
      // HACK: gets rid of that weird space above the gradient delete
      display: none !important;
    }
  }
}

.wrapped#theme-chooser-container {
  padding: 0 !important;
  margin: 0 !important;

  .group {
    .divider-line,
    .title-container {
      display: none !important;
    }

    .themes .explainer {
      color: $text-muted;
      margin-bottom: 22px !important;
    }

    .themes {
      background-color: transparent;
      z-index: 2;

      h4 {
        color: lighten($gray-800, 5%);
      }
    }
  }

  .theme-list {
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;

    .theme {
      display: flex;
      height: 100%;
      border-radius: 1em;
      background-color: darken($themeBgColor, 2%);
      border: 3px solid darken($themeBgColor, 2%);
      margin-right: 2.5em;
      margin-bottom: 2em;

      .container {
        padding: 0 12px !important;
      }

      &:first-child {
        margin-left: 3px;
      }

      &.current {
        border-color: $success;
        background-color: mix($success, $themeBgColor, 7%);
      }

      &:not(.current) {
        cursor: pointer;
        &:hover {
          background-color: darken($themeBgColor, 5%);
          border-color: darken($themeBgColor, 5%);
        }
      }

      .screen-preview {
        $sidebarWidth: 82px;

        position: relative;
        background-color: darken($themeBgColor, 5%);
        border-radius: 0.6em;
        min-height: 220px;
        min-width: 400px;
        overflow: hidden;
        padding-right: calc($sidebarWidth + 24px) !important;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .prompt {
          font-weight: 600;
          font-size: 0.8em;
          letter-spacing: 0.01em;
        }
        .subprompt {
          opacity: 0.7;
          font-size: 0.7em;
          letter-spacing: 0.01em;
        }

        .field-container {
          overflow: hidden;
          border-radius: 0.5em;
          font-size: 0.6em;

          .field-prompt {
            letter-spacing: 0.02em;
          }
          .field-value {
            border-radius: 0.5em;
            background-color: white;
          }
        }

        .buttons {
          .btn {
            font-size: 0.7em;
          }
          .btn:hover {
            cursor: default;
          }
        }

        .sidebar {
          text-align: center;
          font-size: 0.5em;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: $sidebarWidth;

          .logo {
            max-width: 55%;
            max-height: 20px;
          }
          .clinic-name {
            max-width: 65%;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            font-weight: 700;
            font-size: 0.9em;
          }
          .subtitle,
          .steps,
          .final {
            font-size: 0.8em;
          }
        }
      }
    }
  }
}

/**
Batch listing
*/

.issues {
  .issue {
    border: 1px solid #f4f9ff;
    background-color: #f4f9ff;
    border-radius: 0.5em;
    font-size: 0.9em;
    white-space: normal;
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }

    &.editable:hover {
      border-color: #d4e6ff;
      cursor: pointer;
    }
  }
}

/**
Search
*/

// Placing our clear search button

.search-form {
  position: relative;

  .btn-clear-search {
    position: absolute;
    right: 1em;
    top: 9px;

    .fe {
      margin-top: -1px;
      color: $gray-600;
      &:hover {
        color: $gray-700;
      }
    }
  }
}

// Remove this one when all searches are converted
#search-form {
  position: relative;

  #btn-clear-search {
    position: absolute;
    right: 1em;
    top: 9px;

    .fe {
      margin-top: -1px;
      color: $gray-600;
      &:hover {
        color: $gray-700;
      }
    }
  }
}

/**
Other
*/

// inbox
.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

// QR codes
#qr-code-canvas > svg {
  display: block;
  margin: auto;
}
.qr-code-canvas > svg {
  display: block;
  margin: auto;
}

.qr-code-button {
  width: 2vw;
  margin-left: -0.1vw;
  margin-right: 0.6vw;
  margin-top: -0.6em;
  margin-bottom: -0.7em;

  > svg {
    display: block;
    margin: auto;
  }
}

// Ensure that our drop-downs aren't behind our switches (i.e. required switch in form editor)
.custom-control {
  z-index: 0;
}

.navbar-brand {
  margin-left: 0;
  margin-right: auto;
}

.navbar-vertical.navbar-expand-md .navbar-brand {
  text-align: left;
}

.navbar-brand-img,
.navbar-brand > img {
  max-width: 80%;
  max-height: 1.5rem;
}

// Allow material icons in sidebar (unused)
.navbar-nav .nav-link > .material-icons {
  font-size: 17px;
  min-width: 28px;
}

.list-checkbox-all {
  align-self: center;

  & ~ .custom-control-label {
    margin-top: 0.75em;
  }
}

.form-group label:not(.btn) {
  color: $gray-700;
  font-size: 0.95em;
  font-weight: 500;

  & ~ small {
    margin-top: -0.3em;
    margin-bottom: 0.8em;
  }
}

// To get small dots in our statuses (SF has large dots)
.badge span,
.small span {
  font-family: "Inter", sans-serif;
}

a {
  color: $blue;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:hover {
    color: darken($blue, 10%);
  }
}

a.btn > .hide,
button > .hide {
  display: none;
}

a.btn > .show,
button > .show {
  display: flex;
}

a.btn.phx-click-loading,
button.phx-click-loading,
button.phx-submit-loading,
button.still-loading {
  pointer-events: none;

  & > .hide {
    display: block;
  }
  & > .show {
    display: none;
  }
}

.nav-pills {
  .nav-link {
    font-size: 0.9em;
    border-radius: 50px;
    color: $text-muted;
    &:hover {
      color: $gray-700;
    }
    &.active {
      border: 1px solid $gray-300;
      background-color: $gray-200;
      color: $gray-800;
    }
  }
}

.page-item.active .page-link,
.page-item .page-link.active {
  background-color: $gray-100;
  color: $gray-600;
  border-color: $gray-300;
  border-bottom-color: $gray-400;
}

.page-item {
  list-style-type: none;
}

.page-btn {
  border-radius: 4px;
}

.page-item .page-link {
  min-width: 2.5em;
  text-align: center;
}

// To get the form within a card right
@media (min-width: 768px) {
  .navbar-vertical ~ .main-content .container-fluid.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 768px) {
  .navbar-vertical.navbar-expand-md .navbar-brand {
    text-align: right;
  }
}

.sign-in-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card.fade hr {
  border-color: $gray-200;
}

// Used for field group headings.
.text-blocky {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;

  &.text-smaller {
    font-size: 10px;
  }
}

.no-border {
  border: none;
}

.blurred {
  filter: blur(4px);
}

.blurred-more {
  filter: blur(10px);
  pointer-events: none;
}

.border-dark-light {
  border-color: $gray-600-dark;
}

.border-dark-lighter {
  border-color: $gray-800;
}

.navbar-dark .bg-danger-soft {
  background-color: mix($danger, $black-dark, 35%) !important;
}

.navbar-dark .bg-primary-soft {
  background-color: mix($primary, $black-dark, 25%) !important;
}

.text-info-dark {
  color: darken($info, 12%);
}

.bg-info-soft-lighter {
  background-color: saturate(lighten($info, 45%), 35%);
  border-color: saturate(lighten($info, 38%), 35%);
}

.bg-warning-soft-lighter {
  background-color: saturate(lighten($warning, 36%), 20%);
  border-color: saturate(lighten($warning, 30%), 25%);
}

.bg-success-soft-lighter {
  background-color: saturate(lighten($success, 54%), 5%);
  border-color: saturate(lighten($success, 45%), 1%);
}

.bg-danger-soft-lighter {
  background-color: saturate(lighten($danger, 42%), 15%);
  border-color: saturate(lighten($danger, 39%), 20%);
}

.inner-shadow {
  &-top {
    box-shadow: inset 0 20px 10px -10px transparentize($gray-200, 0.7);
  }
}

// Form overrides for cards

.form-check-input[type="checkbox"] {
  margin-right: 0.5em;
}

.card-header {
  .form-control.search-input {
    padding-top: 1em;
    padding-bottom: 1em;
    border: none;
    border-left: 1px solid $gray-100;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: white !important;

    &:focus {
      background-color: #ffffee !important;
    }
  }
}

.form-control.search-input {
  background-color: $gray-100;
  border-color: $gray-300;
  border-radius: 50px;
  padding-left: 1.5em;
  padding-right: 1.5em;

  &:focus {
    border-color: $gray-500;
    border-width: 1px !important;
    background-color: white !important;
  }

  &.inner-shadow-top {
    box-shadow: inset 0 6px 3px -3px transparentize($gray-300, 0.65);
  }
}

body:not(.bg-dark) .main-content .card .form-control,
body:not(.bg-dark) .modal .card .form-control {
  &:not(.form-check-input) {
    border-color: #d4e6ff;
    background-color: #f4f9ff;

    &:focus {
      &:focus {
        background-color: #ffffee;
        background-color: #ffffee;
      }
    }

    &.is-invalid {
      &.is-invalid {
        border-color: $red;
        border-color: $red;
      }
    }
  }
}

body.bg-dark {
  background-color: $black-dark !important;

  .form-check-input:not(:checked) {
    background-color: black !important;
  }
}

// Better field focus.
body:not(.bg-dark) .form-control:not(.form-check-input):focus {
  border-width: 2px;
  background-color: #fff7e3;
}

tr.selected {
  background-color: #f4f9ff;
}

body.bg-dark .form-control:not(.form-check-input):focus {
  border-width: 2px;
  background-color: darken($black, 10%);
}

// Lighter text, bg-agnostic. Used in activation form.
.text-muted-transparency {
  opacity: 0.6;
}

// Used on the form
.text-bright {
  color: $light-blue;
}

.border-blue {
  border-color: $blue;
}

// For highlighting certain text (unused)
.text-highlighted {
  display: inline-block;
  border-radius: 4px;
  padding: 0.5em;
  background-color: rgba(0, 0, 0, 0.2);
  font-weight: bold;

  &-light {
    @extend .text-highlighted;
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.text-line-through,
.text-line-through:hover {
  text-decoration: line-through;
}

// Allow certain elements to seem like they're links
// by changing the cursor (used in activation form).
.pointer {
  cursor: pointer;

  &:hover {
    border-color: $primary;
  }
}

.drag-handle {
  cursor: move;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

// Special buttons

.btn-logo {
  color: white !important;
  height: 64px;

  > img {
    max-width: 32px;
    max-height: 32px;
    margin-right: 0.5em;
  }
}

.btn-nookal {
  @extend .btn-logo;
  background-color: #323c41;
  &:hover {
    background-color: darken(#323c41, 10%);
  }
}

.btn-cliniko {
  @extend .btn-logo;
  background-color: #007fa3;

  &:hover {
    background-color: darken(#007fa3, 10%);
  }
}

.btn-pabau {
  @extend .btn-logo;
  background-color: darken(white, 5%);
  color: rgba(0, 0, 0, 0.65) !important;

  &:hover {
    background-color: darken(white, 10%);
  }
}

// Make buttons have rounded corners
.btn {
  border-radius: 50px;
  padding-left: 1em;
  padding-right: 1em;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-tn-circle {
  width: 22px;
  height: 22px;
  padding: 0 !important;
}

.btn-tnier {
  line-height: 0.8em !important;
  font-size: 0.55rem !important;
}

.btn-help-circle {
  width: 2.3em;
  height: 2.3em;
  padding: 0 !important;
  background-color: saturate(lighten($info, 40%), 20%);
  color: $info;

  & .fe {
    font-size: 1.4em;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50%;
  }

  &:hover {
    background-color: saturate(lighten($info, 38%), 20%);
    color: darken($info, 10%);
  }
}

.btn-tn-circle-close {
  width: 22px;
  height: 22px;
  padding: 0 !important;
  background-color: transparent;
  color: $gray-700;
  border-color: transparent;

  &:hover,
  &:active {
    background-color: white;
  }
}

.btn-squared {
  border-radius: 6px;

  &.btn-tn {
    padding: 0.8em 0em;
  }
}

.btn-sm {
  padding-top: 0.2rem;
  padding-bottom: 0.3rem;
}

// Tiny button
.btn-tn {
  line-height: 1em;
  font-size: 0.75rem;
  padding: 0.8em 1.3em 0.85em 1.3em;
}

.btn-xxs {
  line-height: 1em;
  font-size: 0.7rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  padding: 0.75em 1.25em 0.8em 1.1em;
}

.btn-blue {
  @extend .btn-primary;
  color: white;
  background-color: $blue;
  border-color: $blue;

  &:hover {
    background-color: darken($blue, 10%);
    border-color: darken($blue, 12%);
  }
}

.btn-primary-dark {
  @extend .btn-primary;
  color: white;
  background-color: darken($primary, 10%);
  border-color: darken($primary, 12%);

  &:hover {
    background-color: darken($primary, 20%);
    border-color: darken($primary, 24%);
  }
}
.btn-dark-dark {
  @extend .btn-dark;
  color: white;
  background-color: darken($dark, 10%);
  border-color: darken($dark, 12%);

  &:hover {
    background-color: darken($dark, 20%);
    border-color: darken($dark, 24%);
  }
}

.btn-gold {
  @extend .btn-primary;
  color: $black;
  background-color: $yellow;
  border-color: $yellow;

  &:hover {
    background-color: darken($yellow, 10%);
    border-color: darken($yellow, 12%);
  }
}

.btn-teal {
  @extend .btn-primary;
  background-color: $teal;
  border-color: $teal;

  &:hover {
    background-color: darken($teal, 10%);
    border-color: darken($teal, 12%);
  }
}

.btn-black-transparent {
  @extend .btn-primary;
  background-color: rgba(0, 0, 0, 0.3);
  border-color: transparent;

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
    border-color: transparent;
  }
}

.btn-purple {
  @extend .btn-primary;
  background-color: $purple;
  border-color: $purple;

  &:hover {
    background-color: darken($purple, 10%);
    border-color: darken($purple, 12%);
  }
}

.btn-orange {
  @extend .btn-primary;
  border-color: $orange;
  background-color: $orange;

  &:hover {
    background-color: darken($orange, 10%);
    border-color: darken($orange, 12%);
  }
}

.notification-dot {
  border-radius: 50%;
  position: absolute;
  display: block;
  width: 11px;
  height: 11px;
  background-color: $red;
  top: 0;
  right: 0;
}

.dot-11 {
  display: inline-block;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  margin-left: 6px;
}

.dot-small {
  display: inline-block;
  border-radius: 50%;
  width: 5px;
  height: 5px;
}

.text-strong {
  font-weight: bold;
}

.text-orange {
  color: $orange;
}

.text-purple {
  color: $purple;
}

.navbar {
  &.navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active {
    padding-left: 0.6em;
    padding-right: 0.6em;
  }

  .nav-item {
    font-weight: 500;
    // font-size: 0.9em;

    .nav-link > .fe {
      padding-bottom: 0;
    }
  }

  // For when we have custom integration icons in the nav bar
  .nav-link {
    .custom-nav-icon {
      opacity: 0.5;
      margin-right: 12px;
      height: 19px;
      margin-top: -2px;

      &.active {
        opacity: 1;
      }
    }

    &:hover .custom-nav-icon {
      opacity: 1;
    }
  }

  .nav-link.active {
    background-color: saturate(lighten($purple, 39%), 15%);
    color: $purple;
    border-radius: 0.5em;
    margin: 0 1em;

    &::before {
      border: none !important;
    }
  }

  &.navbar-dark .nav-link.active {
    background-color: $primary;
    color: white;
  }

  &.navbar-dark {
    scrollbar-color: darken($navbar-dark-bg, 5%) darken($navbar-dark-bg, 0%);
  }

  &.navbar-light {
    scrollbar-color: $gray-200 white;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade {
  animation: fade-in 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.modal.showing {
  background-color: rgba(44, 62, 80, 0.55);
  display: block;
}

svg.tea {
  --cup: #d2ddec;
  --steam: #d2ddec;
  // --steam: #39afd1;
  // transform: scale(0.65);

  #teabag {
    transform-origin: top center;
    transform: rotate(3deg);
    animation: swing 2s infinite;
  }

  #steamL {
    stroke-dasharray: 13;
    stroke-dashoffset: 13;
    animation: steamLarge 2s infinite;
  }

  #steamR {
    stroke-dasharray: 9;
    stroke-dashoffset: 9;
    animation: steamSmall 2s infinite;
  }
}
@keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}

@keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }
  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}

.spinner {
  width: 2em;
  height: 2em;
  margin: 0 auto;
  background-color: $gray-400;

  border-radius: 100%;
  animation: sk-scaleout 1.5s infinite ease-in-out;
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@each $color, $value in $theme-colors {
  .pulse-#{$color} {
    box-shadow: 0 0 0 0 $value;
    transform: scale(1);
    animation: pulse-#{$color} 2s infinite;
  }

  @keyframes pulse-#{$color} {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 transparentize($value, 0.5);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px transparentize($value, 1);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 transparentize($value, 1);
    }
  }
}

.text-highlighted {
  background-color: #ffffee;
}

.bg-highlighted {
  background-color: #ffffee;
}

.patient .card-body {
  padding-bottom: 3em;
}

.patient-matches {
  // position: relative;

  .row:last-child {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .remote_practitioner_id_container {
    // overrides default of 100%
    display: flex;
    flex-grow: 1;
    width: auto !important;
  }

  .bg-lines-soft {
    background: repeating-linear-gradient(
      -45deg,
      lighten($gray-200, 1.7%),
      lighten($gray-200, 1.7%) 2px,
      $gray-100 2px,
      $gray-100 8px
    );
  }

  .form-control {
    font-size: 12px;
    height: auto;
    border-width: 2px;
  }

  .match-numbers {
    position: absolute;
    top: -11px;
    left: 20px;
    z-index: 1000;
  }

  .patient-match {
    &:hover,
    &.matching {
      background-color: #ffffee !important;
    }
  }
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background: repeating-linear-gradient(
    -45deg,
    $gray-200,
    $gray-200 5px,
    rgba(255, 255, 255, 0.7) 5px,
    rgba(255, 255, 255, 0.7) 20px
  );

  &.cover-soft {
    background: repeating-linear-gradient(
      -45deg,
      transparentize($gray-200, 0.5),
      transparentize($gray-200, 0.5) 5px,
      rgba(255, 255, 255, 0.7) 5px,
      rgba(255, 255, 255, 0.7) 20px
    );
  }
}

.patient-answers {
  padding: 0;

  .title {
    font-weight: 100;
  }

  .answer-block {
    position: relative;

    &:nth-child(odd) {
      background-color: $gray-100;
    }

    &:first-child {
      margin-bottom: 0;
    }

    h4.prompt {
      position: absolute;
      right: 38px;
      top: 21px;
      max-width: 33%;
      line-height: 1.3em;
      text-align: right;
    }

    .fields {
      max-width: 66%;
    }
  }

  h3.title {
    color: $flatNavyBlueDark;
    font-size: 2em;
    font-weight: 100;
    letter-spacing: 0.03em;
    margin-top: 0.5em;
  }

  h4.prompt {
    color: $gray-500;
    font-weight: 300;
    font-size: 1.3em;
    float: right;
    line-height: 1.4em;
  }

  table.fields {
    width: 100%;

    .prompt {
      color: $flatMint;
      font-weight: 400;
      font-size: 0.9em;
      width: 33%;
      &:empty {
        padding: 0;
        width: 0;
      }
    }

    tr {
      &:first-child > td {
        padding-top: 0;
      }

      & > td {
        padding-top: 0.65em;
      }
    }

    .value {
      color: $flatNavyBlue;
      font-size: 0.9em;
    }
  }
}

.badge {
  display: inline-flex;
  align-items: center;
}

.card.patient {
  overflow: hidden;

  &.processing-failed {
    border: 1px solid lighten($red, 20%);
    box-shadow: 0px 0px 0px 6px transparentize($red, 0.92);
  }
}

.bg-muted-even-more {
  background-color: $gray-200;
}

.bg-muted-more {
  background-color: $gray-400;
}

.text-muted-more {
  color: $gray-400 !important;
}

.text-muted-less {
  color: $gray-700 !important;
}

.border-on-dark-muted {
  border-color: desaturate(
    $color: lighten(#1e3a5c, 1%),
    $amount: 1%
  ) !important;
}

.text-on-dark-muted {
  color: desaturate($color: lighten(#1e3a5c, 20%), $amount: 15%) !important;
}

.text-on-dark-muted-more {
  color: desaturate($color: lighten(#1e3a5c, 10%), $amount: 10%) !important;
}

a.text-on-dark-muted-more {
  &:hover {
    color: white !important;
  }
}

.bg-muted-less {
  background-color: $gray-500;
}

.text-blue {
  color: $blue;
}

.bg-blue {
  background-color: $blue;
}

.half-opaque {
  opacity: 0.5;
}

.card .grid-row {
  &:first-child {
    margin-top: -0.5em;
  }

  &:last-child {
    margin-bottom: -0.4em;
  }
}

.dev-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: repeating-linear-gradient(
    45deg,
    $flatYellow,
    $flatYellow 10px,
    $flatBlack 10px,
    $flatBlack 20px
  );
}

.dev-banner-spacer {
  margin-top: 3px;
}

.highlighted-warning {
  background-color: #fdf3d9;
}

.response-signing-box {
  border-radius: 1em;
  overflow: hidden;
}

.wiz-sig-pad-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  canvas {
    border-top: 1px dashed transparentize($flatNavyBlueDark, 0.8);
    border-bottom: 1px dashed transparentize($flatNavyBlueDark, 0.8);
  }

  .wiz-sig-top-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1em;
    height: 60px;
    min-height: 60px; // firefox needs this

    & > p {
      font-size: 0.9em;
      color: $flatNavyBlueDark;
      margin: 0;
      width: 100%;
      text-align: center;

      & span {
        font-weight: 600;
      }
    }

    button {
      font-size: 0.65em;
      padding: 0.7.5em 1.5em 0.8em 1.6em;

      &.reset {
        background-color: $flatNavyBlueDark;
        opacity: 0.5;
      }

      &.accept {
        background-color: $flatGreen;
      }
    }
  }

  .wiz-sig-bottom-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0 1em;
    height: 60px;
    min-height: 60px; // firefox needs this

    .wiz-sig-bottom-text-container {
      height: 100%;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;

      h1,
      h2 {
        margin: 0;
        font-size: 0.9em;
        font-weight: 500;
        color: transparentize($flatNavyBlueDark, 0.35);
      }

      h1 {
        font-weight: 600;
      }
    }
  }
}

// Headings
h6 {
  font-weight: 700;
}

h5 {
  font-weight: 600;
}

// Profile link

.profile-link {
  width: 100%;
  background-color: transparentize(#000000, 0.8);
  padding: 0.9em 1.5em 0.9em 1.35em;
  border-radius: 10px;
  color: $text-muted;

  &:hover {
    background-color: transparentize(#000000, 0.7);
    color: white;
  }

  .account {
    opacity: 0.7;
    font-size: 0.8em;
  }
}

.navbar-light .profile-link {
  background-color: $gray-200;
  color: $gray-700;

  &:hover {
    background-color: $gray-300;
    color: $black;
  }
}

// Password stength

.strength-word {
  margin-right: 10px;
  margin-bottom: 2px;
}

// Logo previews
.logo-preview {
  max-width: 27%;
  max-height: 27%;
  border-radius: 1em;
  border: 1px solid $gray-400;
  background-color: white;

  &.dark {
    background-color: $flatNavyBlueDark;
    border: 1px solid $flatNavyBlueDark;
  }
}

.logo-preview-larger {
  @extend .logo-preview;
  max-width: 50%;
  max-height: 50%;
}

.logo-upload-target {
  background-color: $gray-200;
  text-align: center;
  border-radius: 1em;
  border: 2px dashed $gray-400;
  overflow: hidden;
}

.alert-top {
  // top: 0px;
  // left: 0px;
  width: 100%;
  // z-index: 9999;
  border-radius: 0px !important;
}

.cover-full {
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.box-shadow {
  box-shadow: $card-box-shadow;
}
